<template>
  <div>
    <loading
      transition="fade"
      :active.sync="waitTimeout"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      color="#efb639"
      :opacity="1"
    >
    </loading>
    <v-btn href="/services/complaints" elevation="0" color="transparent">
      <v-icon left> mdi-chevron-left </v-icon>
      <span>Mes autres réclamations</span>
    </v-btn>
    <v-row>
      <v-col cols="12" md="9">
        <v-card outlined class="my-5 mt-10" :loading="mailLoading" :disabled="mailLoading">
          <v-card-text v-if="!mailLoading" class="profilAgencyAvatar">
            <v-card max-width="fit-content" color="EoleBlue" class="rounded-pill pr-2" flat>
              <v-card-title style="color: white !important">
                {{ complaint.id_display }}
              </v-card-title>
            </v-card>
          </v-card-text>
          <v-row>
            <v-col cols="12" md="3" class="text-left"> </v-col>
            <v-col cols="12" md="6" class="text-center">
              <v-card-text
                v-if="complaint.id_state === 1"
                class="text-center text-h5 mt-5 text-uppercase EoleGreen--text"
              >
                <i class="fa-solid fa-lock-open"></i>
                <v-spacer></v-spacer>

                Réclamation ouverte
              </v-card-text>
              <v-card-text
                v-if="complaint.id_state === 2"
                class="text-center text-h5 mt-5 text-uppercase EoleYellow--text"
              >
                <i class="fa-solid fa-spinner fa-spin"></i>
                <v-spacer></v-spacer>
                Réclamation en cours
              </v-card-text>
              <v-card-text
                v-if="complaint.id_state === 3"
                class="text-center text-h5 mt-5 text-uppercase EoleError--text"
              >
                <i class="fa-solid fa-lock"></i>
                <v-spacer></v-spacer>
                Réclamation clôturée
              </v-card-text>
              <v-card-text v-if="complaint.id_state === 4" class="text-center text-h5 mt-5 text-uppercase">
                <v-chip large color="EoleBlue"> <i class="fa-solid fa-history mr-2"></i> Archivée</v-chip>
              </v-card-text>
              <v-card-text v-if="complaint.id_state === 5" class="text-center text-h5 mt-5 text-uppercase">
                Réclamation initiée
                <v-spacer></v-spacer>
                <v-chip small color="EoleYellow">En attente de la validation</v-chip>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="3" class="text-right pr-5" align-self="center">
              <v-menu right transition="slide-x-transition" close-on-content-click>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-if="complaint.id_state != 1 && isAdmin" @click="putComplaintState(complaint, 1)">
                    <v-list-item-title>
                      <div class="EoleGreen--text">
                        <i class="fa-solid fa-lock-open mr-2"></i> Ouverture de la réclamation
                      </div>
                    </v-list-item-title>
                  </v-list-item>

                  <v-dialog width="600px" v-model="dialogEditComplaint">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on" @click="editItem(complaint)">
                        <v-list-item-title>
                          <div class=""><i class="fa-solid fa-edit mr-2"></i> Modifier la réclamation</div>
                        </v-list-item-title>
                      </v-list-item>
                    </template>

                    <v-card>
                      <v-card-title>Modification de la réclamation {{ complaint.id_display }}</v-card-title>
                      {{ editComplaint }}
                      <v-stepper v-model="stepperEditComplaint" flat>
                        <v-stepper-items class="mt-5">
                          <v-stepper-content step="1" class="no-padding">
                            <v-card-subtitle>Quand avez-vous réceptionné la réclamation ?</v-card-subtitle>
                            <v-card-text>
                              <v-date-picker
                                ref="picker"
                                v-model="editComplaint.date_picker"
                                :value="now"
                                full-width
                                locale="fr-fr"
                              ></v-date-picker>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="2">
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" md="12">
                                  <v-text-field
                                    v-model="editComplaint.social_name"
                                    label="Raison sociale"
                                    messages="*Si entreprise, sinon néant"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-select
                                    label="Genre"
                                    outlined
                                    dense
                                    :items="genderItems"
                                    item-text="text"
                                    item-value="value"
                                    v-model="editComplaint.gender"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    v-model="editComplaint.lastname"
                                    label="Nom"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    v-model="editComplaint.firstname"
                                    label="Prénom"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="8">
                                  <v-text-field
                                    v-model="editComplaint.street"
                                    label="Adresse"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-autocomplete
                                    messages="Entrez un code postal"
                                    :search-input.sync="searchCity"
                                    :items="cityItems"
                                    outlined
                                    v-model="editComplaint.city"
                                    :value="editComplaint.zip"
                                    label="Ville"
                                    clearable
                                    hide-details
                                    hide-selected
                                    dense
                                    no-filter
                                  >
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-title> Entrez un code postal </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <template v-slot:item="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template></v-autocomplete
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    v-model="editComplaint.phone"
                                    label="Téléphone"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    v-model="editComplaint.email"
                                    label="Email"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="3">
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    v-model="editComplaint.reason"
                                    label="Motif de la réclamation"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                  <v-textarea
                                    v-model="editComplaint.comments"
                                    label="Commentaires"
                                    outlined
                                    dense
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12">
                                  <v-select
                                    label="Compagnie en cause"
                                    outlined
                                    dense
                                    :items="insurersProposed"
                                    item-text="name"
                                    item-value="id"
                                    v-model="editComplaint.id_insurer"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12">
                                  <v-select
                                    label="Produit concerné"
                                    outlined
                                    dense
                                    :items="productsProposed"
                                    item-text="name"
                                    item-value="id"
                                    v-model="editComplaint.id_product"
                                  >
                                    <template v-slot:item="{ item }">
                                      <i :class="`fa-solid fa-${item.icon} mr-2`"></i> {{ item.name }}
                                    </template>
                                  </v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12">
                                  <v-text-field
                                    v-model="editComplaint.contract_number"
                                    label="Numéro de contrat"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="4">
                            <v-card-subtitle>Quel est le niveau de complexité ?</v-card-subtitle>

                            <v-card-text>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-card
                                        @click="
                                          editComplaint.id_complexity = 1
                                          stepperEditComplaint++
                                        "
                                        v-on="on"
                                        outlined
                                        elevation="3"
                                        height="200"
                                        width="200"
                                        class="rounded-xl text-center flex d-flex flex-column justify-center align-center"
                                      >
                                        <v-row align-content="center">
                                          <v-col cols="12" md="12" align-self="center">
                                            <v-card-text class="align-center">
                                              <i class="fa-solid fa-building fa-3x EoleBlue--text"></i>
                                            </v-card-text>
                                            <v-card-text> Simple</v-card-text>
                                          </v-col>
                                        </v-row>
                                      </v-card>
                                    </template>
                                    <span>Ce niveau relève de la gestion en agence de la réclamation</span>
                                  </v-tooltip>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-card
                                        @click="
                                          editComplaint.id_complexity = 2
                                          stepperEditComplaint++
                                        "
                                        v-on="on"
                                        outlined
                                        elevation="3"
                                        height="200"
                                        width="200"
                                        class="rounded-xl text-center flex d-flex flex-column justify-center align-center"
                                      >
                                        <v-row align-content="center">
                                          <v-col cols="12" md="12" align-self="center">
                                            <v-card-text class="align-center">
                                              <i
                                                class="fa-solid fa-building-circle-arrow-right fa-3x EoleBlue--text"
                                              ></i>
                                            </v-card-text>
                                            <v-card-text> Complexe</v-card-text>
                                          </v-col>
                                        </v-row>
                                      </v-card>
                                    </template>
                                    <span>Ce niveau relève de la gestion compagnie de la réclamation</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-stepper-content>

                          <v-stepper-content step="5">
                            <v-card-subtitle>Documents à intégrer à la réclamation</v-card-subtitle>
                            <v-row class="text-center align-center justify-center mb-5">
                              <v-col
                                cols="12"
                                md="6"
                                v-for="(document, indexDocument) in editComplaint.documents"
                                v-bind:key="indexDocument"
                              >
                                <div>
                                  {{ document.name }} - {{ document.date }}
                                  <span v-if="document === null && fileUrl === null"
                                    >Aucun document n'est pour le moment disponible.</span
                                  >
                                  <iframe
                                    v-else
                                    id="lddcVisualisator"
                                    style="width: 100%; height: 500px"
                                    :src="filesUrlEdit[indexDocument]"
                                  ></iframe>
                                </div>
                                <v-card-text v-if="document.url != null">
                                  <v-row align="center">
                                    <v-col cols="12" md="6">
                                      <v-btn
                                        color="EoleError"
                                        class="white--text"
                                        @click="deleteFile(editComplaint.documents, indexDocument)"
                                      >
                                        supprimer
                                      </v-btn></v-col
                                    >
                                  </v-row>
                                </v-card-text>
                              </v-col>
                              <v-col cols="12" md="6" class="d-flex">
                                <v-card
                                  @click="openFileExplorerEdit()"
                                  link
                                  outlined
                                  class="drop-zone flex d-flex flex-column justify-center text-center"
                                >
                                  <v-card-text>
                                    <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                                  </v-card-text>
                                  <v-card-text>Ajout d'un document</v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                            <v-card-text> </v-card-text>
                          </v-stepper-content>
                        </v-stepper-items>
                      </v-stepper>
                      <v-card-text> </v-card-text>
                      <v-card-actions>
                        <v-btn
                          v-if="stepperEditComplaint < 5 && stepperEditComplaint > 1"
                          text
                          @click="stepperEditComplaint--"
                          >précédent</v-btn
                        >
                        <v-btn text v-else @click="dialogAddComplaint = false">annuler</v-btn>

                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          v-if="stepperEditComplaint < 5 && stepperEditComplaint != 4"
                          @click="stepperEditComplaint++"
                        >
                          suivant</v-btn
                        >
                        <v-btn
                          v-else-if="stepperEditComplaint === 5"
                          class="white--text"
                          color="EoleYellow"
                          @click="putComplaint()"
                        >
                          <v-icon left>mdi-check</v-icon>
                          Modifier la réclamation</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-list-item v-if="complaint.id_state === 3 && isAdmin" @click="putComplaintState(complaint, 4)">
                    <v-list-item-title class="EoleBlue--text">
                      <div><i class="fa-solid fa-history mr-3"></i> Archivée la réclamation</div>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item v-if="complaint.id_state === 1 && isAdmin" @click="putComplaintState(complaint, 3)">
                    <v-list-item-title class="EoleError--text">
                      <div><i class="fa-solid fa-lock mr-3"></i> Clôturée la réclamation</div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu></v-col
            >
          </v-row>

          <v-card-text class="mt-5">
            <v-row v-if="!!complaint">
              <v-col cols="12" md="6" class="text-left">
                <strong>Demande initiée par :</strong> {{ complaint.lastname_creator }}
                {{ complaint.firstname_creator }}
                <br />

                <strong>Demande validée par :</strong> {{ complaint.lastname_validator }}
                {{ complaint.firstname_validator }}
                <div v-if="complaint.social_name != null">
                  <strong>Raison sociale :</strong> {{ complaint.social_name }}
                </div>
                <div>
                  <strong>Réclamant :</strong> {{ complaint.civility }} {{ complaint.lastname }}
                  {{ complaint.firstname }}
                </div>
                <strong>Email du réclamant :</strong> {{ complaint.email }}<br />
                <strong>Téléphone du réclamant :</strong> {{ complaint.phone_formatted }}<br />
                <br />
              </v-col>
              <v-col cols="12" md="6" class="text-right"
                ><strong> Date de réception : </strong> {{ complaint.date_received }} <br />
                <strong> Complexité : </strong> {{ complaint.complexity_designation }} <br />
                <v-spacer> </v-spacer>
                <h3>
                  <a
                    :href="`https://www.google.fr/maps/place/${complaint.street} ${complaint.zip} ${complaint.city}`"
                    target="_blank"
                    >{{ complaint.street }} <br />
                    {{ complaint.zip }}, {{ complaint.city }}</a
                  >
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="mt-10">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field readonly v-model="complaint.company_name" outlined label="Compagnie mise en cause" dense>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="mt-n5">
                <v-text-field readonly v-model="complaint.product_name" outlined label="Produit concerné" dense>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="mt-n5">
                <v-text-field readonly v-model="complaint.contract_number" outlined label="Numéro de contrat" dense>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="mt-n5">
                <v-text-field readonly v-model="complaint.reason" outlined label="Motif" dense> </v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="mt-n5">
                <v-textarea
                  readonly
                  v-model="complaint.comments"
                  outlined
                  label="Commentaires"
                  auto-grow
                  dense
                  class="full-height"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12" md="7" class="mt-n5 d-flex">
                <v-row>
                  <v-col cols="12">Documents disponible à l'ouverture de la réclamation :</v-col>
                  <v-col cols="12" class="mt-n6">
                    <v-list dense class="rounded-xl" style="z-index: 0">
                      <v-list-item
                        v-for="(document, index) in complaint.documents"
                        v-bind:key="index"
                        link
                        :href="document.url"
                        target="_blank"
                      >
                        <v-list-item-avatar size="32">
                          <v-avatar color="primary white--text" size="32">
                            <i
                              :class="[
                                'fa-solid ',
                                document.type === 'application/pdf' ? 'fa-file-pdf' : 'fa-file-image',
                                'fa-1x',
                              ]"
                            ></i>
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ document.name }}</v-list-item-title>
                          <v-list-item-subtitle>Importé le : {{ document.date }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text v-text="document.size"></v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" class="d-flex">
        <v-card outlined flat class="my-5 mt-10 w-full">
          <v-card-title class="text-center justify-center"
            >Suivi de la réclamation <br />{{ complaint.id_display }}</v-card-title
          >
          <v-card-text v-if="complaint.logs.length > 0" class="timeline-container">
            <v-timeline align-top dense>
              <v-timeline-item
                icon-color="white"
                v-for="(log, index) in complaint.logs"
                v-bind:key="index"
                :color="log.type === 'success' ? 'EoleGreen' : log.type === 'edit' ? 'EoleBlue' : 'EoleBlue'"
                :icon="log.type === 'success' ? 'mdi-check' : log.type === 'wait' ? 'mdi-pencil' : 'mdi-play'"
              >
                <v-row class="pt-1 px-5">
                  <v-col>
                    <strong>{{ log.datetime_display }}</strong>
                    <div class="text-caption">{{ log.info }}</div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
          <v-card-text v-else class="text-center">
            <div class="text-caption">Aucun suivi n'est disponible pour le moment</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="mb-5"></v-divider>
    <v-card-title class="no-padding mb-3 mt-5">Actions liés à la réclamation </v-card-title>
    <v-row>
      <v-col cols="12" md="4" v-for="(task, index) in complaint.tasks" v-bind:key="task.id" class="d-flex">
        <v-card outlined class="text-center justify-center flex d-flex flex-column rounded-xl" elevation="3">
          <v-card-title style="height: 80px !important">
            <v-row>
              <v-col cols="12" md="6" class="text-left" align-self="center"> Tâche {{ index + 1 }} </v-col>
              <v-col cols="12" md="6" class="text-right">
                <v-chip v-if="parseInt(task.finish) === 0" small color="EoleYellow">Tâche en cours</v-chip>

                <v-chip v-else small color="EoleError">Tâche terminée</v-chip>
                <v-menu v-if="parseInt(task.finish) === 0" right transition="slide-x-transition" close-on-content-click>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="complaint.id_state != 1"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      style=""
                      class="justify-center text-center"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-dialog v-model="dialogEditAction" width="800px" @click:outside="reinitializeDialog">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-title>
                            <div class=""><i class="fa-solid fa-edit mr-2"></i> Action</div>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-stepper v-model="stepperEditAction" flat>
                          <v-stepper-items>
                            <v-stepper-content step="1" class="text-center">
                              <v-card-subtitle>La réclamation doit-elle être cloturée ?</v-card-subtitle>
                              <v-btn
                                @click="
                                  stepperEditAction++
                                  stepperEdit = true
                                "
                                >Oui</v-btn
                              >

                              <v-btn
                                @click="
                                  stepperEditAction++
                                  stepperEdit = false
                                "
                                >Non</v-btn
                              >
                            </v-stepper-content>
                            <div v-if="!stepperEdit">
                              <v-stepper-content step="2" class="text-center">
                                <v-card-title>Action à replanifier</v-card-title>
                                <v-card-text class="no-padding">
                                  <v-row>
                                    <v-col cols="12" md="2" class="mt-2"> Qui doit agir ? </v-col>
                                    <v-col cols="12" md="9" class="ml-n2">
                                      <v-select
                                        v-model="newAnotherTask.id_collaborator"
                                        label="Collaborateur "
                                        outlined
                                        dense
                                        :items="collaborators"
                                        item-text="name"
                                        item-value="id"
                                      >
                                        <template v-slot:selection="{ item }">
                                          {{ item.lastname }} {{ item.firstname }}
                                        </template>
                                        <template v-slot:item="{ item }">
                                          {{ item.lastname }} {{ item.firstname }}
                                        </template>
                                      </v-select>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="2" class="mt-2"> Date butoire ? </v-col>
                                    <v-col cols="12" md="9" class="ml-n2">
                                      <v-text-field
                                        v-model="newAnotherTask.date"
                                        dense
                                        outlined
                                        label="Date"
                                        type="date"
                                      >
                                      </v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row class="mt-2">
                                    <v-col cols="12" md="2" class="mt-2"> Vers qui ? </v-col>
                                    <v-col cols="12" md="9" class="ml-n2">
                                      <v-select
                                        v-model="newAnotherTask.receiver"
                                        label="Action"
                                        outlined
                                        dense
                                        :items="actions"
                                        item-text="title"
                                        item-value="title"
                                      >
                                        <template v-slot:item="{ item }"> {{ item.title }} </template>
                                      </v-select>
                                    </v-col>
                                  </v-row>

                                  <v-row v-if="newAnotherTask.receiver === 'Autre'">
                                    <v-col cols="12" md="2" class="mt-2"> Commentaire :</v-col>
                                    <v-col cols="12" md="9" class="ml-n2">
                                      <v-textarea
                                        v-model="newAnotherTask.comments_receiver"
                                        dense
                                        outlined
                                        label="Commentaire"
                                      >
                                      </v-textarea>
                                    </v-col>
                                  </v-row>

                                  <v-row class="mt-2">
                                    <v-col cols="12" md="2" class="mt-2"> Quelle action ? </v-col>
                                    <v-col cols="12" md="9" class="ml-n2">
                                      <v-select
                                        v-model="newAnotherTask.action"
                                        label="Action"
                                        outlined
                                        dense
                                        :items="informationsType"
                                        item-text="title"
                                        item-value="title"
                                      >
                                        <template v-slot:item="{ item }"> {{ item.title }} </template>
                                      </v-select>
                                    </v-col>
                                  </v-row>

                                  <v-row v-if="!!newAnotherTask.action">
                                    <v-col cols="12" md="2" class="mt-2"> Commentaire de l'action :</v-col>
                                    <v-col cols="12" md="9" class="ml-n2">
                                      <v-textarea
                                        v-model="newAnotherTask.comments_action"
                                        dense
                                        outlined
                                        label="Commentaire"
                                      >
                                      </v-textarea>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-stepper-content>

                              <v-stepper-content step="3" class="text-center">
                                <v-card-subtitle>Preuve à ajouter à la GED</v-card-subtitle>
                                <v-row class="">
                                  <v-col
                                    cols="12"
                                    md="6"
                                    v-for="(document, indexDocument) in newAnotherTask.documents"
                                    v-bind:key="indexDocument"
                                  >
                                    <div>
                                      {{ document.name }} - {{ document.date }}
                                      <span v-if="document === null && fileUrl === null"
                                        >Aucun document n'est pour le moment disponible.</span
                                      >
                                      <iframe
                                        v-else
                                        id="lddcVisualisator"
                                        style="width: 100%; height: 500px"
                                        :src="filesUrl[indexDocument]"
                                      ></iframe>
                                    </div>

                                    <v-card-text v-if="document.url != null">
                                      <v-row align="center">
                                        <v-col cols="12" md="6">
                                          <v-btn color="EoleError" class="white--text" @click="deleteFile(document)">
                                            supprimer
                                          </v-btn></v-col
                                        >
                                        <v-col cols="12" md="6">
                                          <v-btn
                                            color="EoleBlue"
                                            class="white--text"
                                            @click="downloadFile(`${document.url}?cache=${Date.now()}`)"
                                          >
                                            Télécharger
                                          </v-btn></v-col
                                        >
                                      </v-row>
                                    </v-card-text>
                                  </v-col>
                                  <v-col cols="12" md="6" class="d-flex">
                                    <v-card
                                      @click="openFileExplorer2()"
                                      link
                                      outlined
                                      @dragover.prevent
                                      v-model="document.data"
                                      class="drop-zone flex d-flex flex-column justify-center text-center"
                                    >
                                      <v-card-text>
                                        <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                                      </v-card-text>
                                      <v-card-text>Ajout d'un document</v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-stepper-content>
                              <v-card-actions class="justify-center">
                                <v-btn v-if="stepperEditAction === 2" @click="stepperEditAction++">suivant</v-btn>
                                <v-btn
                                  v-if="stepperEditAction === 3"
                                  color="EoleGreen"
                                  class="white--text"
                                  @click="postTask(newAnotherTask, task.id)"
                                >
                                  <v-icon left>mdi-check</v-icon> Valider</v-btn
                                >
                              </v-card-actions>
                            </div>
                            <div v-else>
                              <v-stepper-content step="2" class="text-center">
                                <v-card-title>Informations de cloture de la réclamation</v-card-title>
                                <v-card-text class="no-padding">
                                  <v-row>
                                    <v-col cols="12" md="2" class="mt-2"> Qui agit ? </v-col>
                                    <v-col cols="12" md="9" class="ml-n2">
                                      <v-select
                                        v-model="closer.idCollaboratorCloser"
                                        label="Collaborateur "
                                        outlined
                                        dense
                                        :items="collaborators"
                                        item-text="name"
                                        item-value="id"
                                      >
                                        <template v-slot:selection="{ item }">
                                          {{ item.lastname }} {{ item.firstname }}
                                        </template>
                                        <template v-slot:item="{ item }">
                                          {{ item.lastname }} {{ item.firstname }}
                                        </template>
                                      </v-select>
                                    </v-col>
                                  </v-row>

                                  <v-row class="mt-2">
                                    <v-col cols="12" md="2" class="mt-2"> satisfaction du client ? </v-col>
                                    <v-col cols="12" md="9" class="ml-n2">
                                      <v-select
                                        v-model="closer.satisfaction"
                                        label="Action"
                                        outlined
                                        dense
                                        :items="satisfactionItems"
                                      >
                                      </v-select>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" md="2" class="mt-2"> Conclusion :</v-col>
                                    <v-col cols="12" md="9" class="ml-n2">
                                      <v-textarea v-model="closer.conclusion" dense outlined label="Commentaire">
                                      </v-textarea>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-btn
                                  @click="
                                    generateDocumentComplaintClosure()
                                    stepperEditAction++
                                  "
                                  >Suivant</v-btn
                                >
                              </v-stepper-content>
                              <v-stepper-content step="3" class="text-center">
                                <v-card-title>Document à transmettre au client pour signature</v-card-title>
                                <v-card-text class="no-padding">
                                  <iframe id="documentVisualisator" style="width: 100%; height: 500px"> </iframe>
                                  <v-btn
                                    @click="
                                      putComplaintState(complaint, 3)
                                      dialogEditAction = false
                                      stepperEditAction = 1
                                    "
                                  >
                                    Clôturer la réclamation {{ complaint.id_display }}
                                  </v-btn>
                                </v-card-text>
                              </v-stepper-content>
                            </div>
                          </v-stepper-items>
                        </v-stepper>
                      </v-card>
                    </v-dialog>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-title>
          <v-row>
            <v-col cols="12" md="12">
              <v-card-text class="align-center">
                <v-row>
                  <v-col cols="12" md="4" class="text-left"> Pour : </v-col>
                  <v-col cols="12" md="8" class="text-left"> {{ task.lastname }} {{ task.firstname }} </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" class="text-left"> Date butoire : </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    {{ task.date_end }}
                  </v-col>
                  <v-col v-if="task.anteriatory" cols="12" md="4" class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <i v-on="on" class="fa-solid fa-warning EoleError--text fa-fade fa-2x"></i>
                      </template>
                      <span
                        >Un retard à été constaté sur la tâche {{ index + 1 }} <br />
                        {{ task.time }} jours de retard</span
                      >
                    </v-tooltip>
                  </v-col>
                  <v-col v-else cols="12" md="4" class="text-right"> ({{ task.time }} jours restant) </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" class="text-left"> Information : </v-col>
                  <v-col cols="12" md="8" class="text-left"> {{ task.receiver }} </v-col>
                </v-row>
                <v-row v-if="task.receiver === 'Autre'">
                  <v-col cols="12" md="4" class="text-left"> Commentaire : </v-col>
                  <v-col cols="12" md="8" class="text-left">
                    <v-textarea outlined dense v-model="task.comments_receiver" readonly auto-grow></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" class="text-left"> Action : </v-col>
                  <v-col cols="12" md="8" class="text-left"> {{ task.action }} </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" class="text-left"> Commentaire de l'action menée : </v-col>
                  <v-col cols="12" md="8" class="text-left">
                    <v-textarea outlined dense v-model="task.comments_action" readonly auto-grow></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="task.documents.length > 0" class="text-left no-padding">
                <v-divider class="my-5"></v-divider>

                <v-card-subtitle>Documents :</v-card-subtitle>
                <v-list dense class="rounded-xl" style="z-index: 0">
                  <v-list-item
                    v-for="(document, index) in task.documents"
                    v-bind:key="index"
                    link
                    :href="document.url"
                    target="_blank"
                  >
                    <v-list-item-avatar size="32">
                      <v-avatar color="primary white--text" size="32">
                        <i
                          :class="[
                            'fa-solid ',
                            document.type === 'application/pdf' ? 'fa-file-pdf' : 'fa-file-image',
                            'fa-1x',
                          ]"
                        ></i>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ document.name }}</v-list-item-title>
                      <v-list-item-subtitle>Importé le : {{ document.date }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text v-text="document.size"></v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-row class="text-left no-padding">
                <v-col cols="12"> </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" v-if="complaint.id_state === 1" class="d-flex">
        <v-dialog v-model="dialogAddTask" width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              min-height="250px"
              v-bind="attrs"
              v-on="on"
              width=""
              class="text-center justify-center flex d-flex flex-column rounded-xl"
              elevation="3"
              link
            >
              <v-row align-content="center">
                <v-col cols="12" md="12" align-self="center">
                  <v-card-text class="align-center">
                    <v-icon size="48" color="EoleBlue">mdi-plus-circle</v-icon>
                  </v-card-text>
                  <v-card-text> Ajouter une tâche </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </template>
          <v-card>
            <v-card-title class="justify-center"> Planifier une action sur la réclamation </v-card-title>
            <v-card-text class="mt-5">
              <v-stepper v-model="stepperAddActions" flat>
                <v-stepper-items>
                  <v-stepper-content step="1" class="no-padding">
                    <v-card-text class="no-padding mt-5">
                      <v-row>
                        <v-col cols="12" md="2" class="mt-2"> Qui doit agir ? </v-col>
                        <v-col cols="12" md="9" class="ml-n2">
                          <v-select
                            v-model="action.id_collaborator"
                            label="Collaborateur "
                            outlined
                            dense
                            :items="collaborators"
                            item-text="name"
                            item-value="id"
                          >
                            <template v-slot:selection="{ item }"> {{ item.lastname }} {{ item.firstname }} </template>
                            <template v-slot:item="{ item }"> {{ item.lastname }} {{ item.firstname }} </template>
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="2" class="mt-2"> Date butoire ? </v-col>
                        <v-col cols="12" md="9" class="ml-n2">
                          <v-text-field v-model="action.date" dense outlined label="Date" type="date"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <v-col cols="12" md="2" class="mt-2"> Vers qui ? </v-col>
                        <v-col cols="12" md="9" class="ml-n2">
                          <v-select
                            v-model="action.receiver"
                            label="Action"
                            outlined
                            dense
                            :items="actions"
                            item-text="title"
                            item-value="title"
                          >
                            <template v-slot:item="{ item }"> {{ item.title }} </template>
                          </v-select>
                        </v-col>
                      </v-row>

                      <v-row v-if="action.receiver === 'Autre'">
                        <v-col cols="12" md="2" class="mt-2"> Commentaire :</v-col>
                        <v-col cols="12" md="9" class="ml-n2">
                          <v-textarea v-model="action.comments_receiver" dense outlined label="Commentaire">
                          </v-textarea>
                        </v-col>
                      </v-row>

                      <v-row class="mt-2">
                        <v-col cols="12" md="2" class="mt-2"> Quelle action ? </v-col>
                        <v-col cols="12" md="9" class="ml-n2">
                          <v-select
                            v-model="action.action"
                            label="Action"
                            outlined
                            dense
                            :items="informationsType"
                            item-text="title"
                            item-value="title"
                          >
                            <template v-slot:item="{ item }"> {{ item.title }} </template>
                          </v-select>
                        </v-col>
                      </v-row>

                      <v-row v-if="!!action.action">
                        <v-col cols="12" md="2" class="mt-2"> Commentaire de l'action :</v-col>
                        <v-col cols="12" md="9" class="ml-n2">
                          <v-textarea v-model="action.comments_action" dense outlined label="Commentaire"> </v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn elevation="0" @click="dialogAddTask = false">annuler</v-btn>
                      <v-btn elevation="0" @click="stepperAddActions++">suivant</v-btn>
                    </v-card-actions>
                  </v-stepper-content>
                  <v-stepper-content step="2" class="text-center mt-n5">
                    <v-row class="text-center align-center justify-center mb-5">
                      <v-col
                        cols="12"
                        md="6"
                        v-for="(document, indexDocument) in action.documents"
                        v-bind:key="indexDocument"
                      >
                        <div>
                          {{ document.name }} - {{ document.date }}
                          <span v-if="document === null && fileUrl === null"
                            >Aucun document n'est pour le moment disponible.</span
                          >
                          <iframe
                            v-else
                            id="lddcVisualisator"
                            style="width: 100%; height: 500px"
                            :src="filesUrl[indexDocument]"
                          ></iframe>
                        </div>
                        <v-card-text v-if="document.url != null">
                          <v-row align="center">
                            <v-col cols="12" md="6">
                              <v-btn color="EoleError" class="white--text" @click="deleteFile(document)">
                                supprimer
                              </v-btn></v-col
                            >
                            <v-col cols="12" md="6">
                              <v-btn
                                color="EoleBlue"
                                class="white--text"
                                @click="downloadFile(`${document.url}?cache=${Date.now()}`)"
                              >
                                Télécharger
                              </v-btn></v-col
                            >
                          </v-row>
                        </v-card-text>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex">
                        <v-card
                          @click="openFileExplorer()"
                          link
                          outlined
                          @dragover.prevent
                          v-model="document.data"
                          class="drop-zone flex d-flex flex-column justify-center text-center"
                        >
                          <v-card-text>
                            <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                          </v-card-text>
                          <v-card-text>Ajout d'un document</v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-btn color="EoleGreen" class="white--text" @click="postTask(action, null)">Valider</v-btn>
                  </v-stepper-content>
                </v-stepper-items></v-stepper
              >
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueTree from "@ssthouse/vue-tree-chart"
Vue.component("vue-tree", VueTree)

import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import imageData from "@/views/config/imageData"

pdfMake.vfs = pdfFonts.pdfMake.vfs

init("user_gUunL4KW2ImPaGngWHYhP")

// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import moment from "moment"
import { getCookie } from "@/utils/cookies"

Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    documentGenerate: "",
    stepperEdit: false,
    dialogEditAction: false,
    newAnotherTask: { documents: [], documentsInformations: [] },
    stepperEditAction: 1,
    dialogNoInformations: {},
    collaborators: [],
    dialogAddTask: false,
    now: new Date().toISOString().substr(0, 10), // Obtient la date du jour au format YYYY-MM-DD
    dialogYes: false,
    dialogNo: false,
    action: { documents: [], documentsInformations: [] },
    informationsType: [
      {
        title: "Email",
        value: 1,
        icon: "fa-solid fa-at",
      },
      {
        title: "Téléphone",
        value: 2,
        icon: "fa-solid fa-phone",
      },
      {
        title: "Courrier",
        value: 3,
        icon: "fa-solid fa-envelope",
      },
    ],
    stepperAddActions: 1,
    actions: [
      {
        title: "Information vers le client",
        value: 1,
        icon: "fa-solid fa-street-view",
      },
      {
        title: "Échange avec la compagnie",
        value: 2,
        icon: "fa-solid fa-building",
      },
      {
        title: "Information vers un tiers",
        value: 3,
        icon: "fa-solid fa-person-circle-question",
      },
      {
        title: "Échange avec un expert",
        value: 4,
        icon: "fa-solid fa-person-circle-check",
      },
      {
        title: "Échange interne cabinet",
        value: 5,
        icon: "fa-solid fa-person-circle-check",
      },
      {
        title: "Autre",
        value: 6,
        icon: "fa-solid fa-person-circle-check",
      },
    ],
    satisfactionItems: ["Satisfait", "Insatisfait"],
    genderItems: [
      { text: "Homme", value: "M" },
      { text: "Femme", value: "F" },
    ],
    closer: {
      idCollaboratorCloser: "",
      satisfaction: "",
      conclusion: "",
    },
    isAdmin: false,
    dialogEditComplaint: false,
    stepperEditComplaint: 1,
    editComplaint: {
      lastname: "",
      firstname: "",
      gender: "",
      email: "",
      phone: "",
      street: "",
      city: "",
      zip: "",
      reason: "",
      comments: "",
      id_insurer: "",
      date: "",
      product: "",
      id_complexity: "",
      contract_number: "",
      documentsInformations: [],
    },
    complaint: {},
    mailLoading: false,
    productsProposed: [],
    insurersProposed: [],
    searchCity: "",
    cityItems: [],
    document: {
      data: null,
      name: "",
      date: "",
      url: "",
    },
    filesUrl: [],
    filesUrlEdit: [],
    documents: [],
    documentsData: [],
  }),

  async created() {
    this.$store.state.user.grade === "administrateur" ? (this.isAdmin = true) : (this.isAdmin = false)

    this.agencyName = localStorage.getItem("agency")
    const urlParams = new URLSearchParams(window.location.search)
    const idComplaint = urlParams.get("id")

    if (urlParams.get("id") === null) {
      this.$router.push("error-404")
    } else {
      this.fetchComplaintById(idComplaint).then(() => {
        document.title = "Fiche réclamation - " + this.complaint.id_display + " | " + this.agencyName

        this.fetchProducts()
        this.fetchInsurers()
        this.fetchUsers()
      })
    }
  },

  components: {
    appbar,
    Loading,
  },
  watch: {
    searchCity(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.editComplaint.zip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
  },
  methods: {
    deleteFile(array, index) {
      array.splice(index, 1)
      this.filesUrlEdit.splice(index, 1)
    },
    reinitializeDialog() {
      this.dialogEditAction = false
      this.stepperEditAction = 1
      this.newAnotherTask = { documents: [], documentsInformations: [] }
    },
    generateDocumentComplaintClosure() {
      var dd = {
        info: {
          title: `Courrier de cloture de réclamation`,
        },
        footer: {
          columns: [
            {
              text: "La SARL ANAVEL, société au capital social de 446 145,00 €, agit en qualité d’agent général d’assurance GAN immatriculée auprès de l’ORIAS sous le numéro 12 065 563 (www.orias.fr) et est soumise à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR) 4 place de Budapest, CS 92459 75436 Paris cedex 09. La SARL C2T Assurances est soumise à une obligation contractuelle d’exclusivité avec GAN ASSURANCES et est autorisée à réaliser des opérations de courtage accessoires. La SARL ANAVEL ne dispose pas de lien financier avec GAN ASSURANCES.Numéro de RCS: 538 591 439 ; Garantie Financière et assurances de Responsabilité Civile Professionnelle conformes au code des assurances (contrat numéro 45880, chez l’assureur CGPA). En cas de réclamation, contactez l’agence au numéro suivant : 02 99 73 00 27 ou par mail : contact@anavel.bzh Médiateur : www.mediateur-geia.fr. Par voie électronique, à l’adresse suivante : contact@mediateur-geia.fr ",
              fontSize: 6,
              margin: [5, 0, 5, 0],
            },
          ],
        },
        content: [],
        styles: {
          header: {
            fontSize: 11,
            bold: true,
          },
          subheader: {
            fontSize: 11,
            bold: true,
          },
          quote: {
            italics: true,
          },
          small: {
            fontSize: 8,
          },
          test: {
            fontSize: 10,
          },
        },
      }
      dd.content.push(
        {
          alignment: "center",
          image: imageData.anavel_logo,
          width: 100,
        },
        "\n",
        "\n",
        "\n",
        "\n",
        {
          alignment: "justify",
          columns: [
            {
              text: `ANAVEL\r1 Avenue des prunus\n35270 Combourg`,
            },
          ],
        },
        "\n",
        {
          columns: [
            {},
            {},
            {
              text: `${this.complaint.civility} ${this.complaint.lastname} ${this.complaint.firstname}\r${this.complaint.street}\r
                  ${this.complaint.zip} ${this.complaint.city}`,
            },
          ],
        },
        "\n",
        "\n",
        "\n",
        "\n",
        {
          text: `Objet : Traitement de votre réclamation n°${this.complaint.id_display} \n`,
          bold: true,
        },
        "\n",
        {
          text: `${this.complaint.civility} ${this.complaint.lastname} ${this.complaint.firstname}, \n
            Vous nous avez solicité dans le cadre d'une réclamation en date du ${this.complaint.date_received}.
            Vous trouverez ci-dessous l'ensemble des éléments qui nous ont permis de traiter votre demande :`,
          style: "test",
        },
        "\n",
        {
          table: {
            headerRows: 1,
            widths: ["25%", "25%", "25%", "25%"],
            body: [
              [
                {
                  border: [false, false, false, false],

                  fillColor: "#00183A",
                  stack: [
                    {
                      columns: [
                        {
                          /// align texte verticaly center

                          text: "Date",
                          color: "#FBB900",
                          // margin: [5, 0, 0, 0],
                          // align verticaly center
                        },
                      ],
                    },
                  ],
                  style: "subheader",
                },
                {
                  border: [false, false, false, false],

                  fillColor: "#00183A",
                  stack: [
                    {
                      columns: [
                        {
                          /// align texte verticaly center

                          text: "Action réalisée",
                          color: "#FBB900",
                          // margin: [5, 0, 0, 0],
                          // align verticaly center
                        },
                      ],
                    },
                  ],
                  style: "subheader",
                },
                {
                  border: [false, false, false, false],

                  fillColor: "#00183A",
                  stack: [
                    {
                      columns: [
                        {
                          text: "Type d'échange",
                          color: "#FBB900",
                          // margin: [5, 0, 0, 0],
                        },
                      ],
                    },
                  ],
                  style: "subheader",
                },
              ],
              ...this.complaint.tasks.map(task => {
                return [
                  {
                    border: [false, false, false, true],

                    text: `Le ${moment(task.date_end).format("Do MMMM YYYY")}`,
                    style: "test",
                  },
                  {
                    border: [false, false, false, true],

                    text: task.receiver,
                    style: "test",
                  },

                  {
                    border: [false, false, false, true],
                    text: task.action,
                    style: "test",
                  },
                ]
              }),
            ],
          },
        },

        "\n",
        {
          text: `Après analyse de votre situation et des différents échanges que nous avons pu avoir avec l'ensemble des parties prenantes nous considérons que votre réclamation à été traitée et nous vous informons que nous venons de clôturer cette dernière.`,
          style: "test",
        },
        "\n",
        {
          text: `Conclusion :`,
          style: "test",
        },
        "\n",
        {
          table: {
            headerRows: 1,
            widths: ["100%"],
            body: [
              [
                {
                  stack: [
                    {
                      columns: [
                        {
                          /// align texte verticaly center

                          text: this.closer.conclusion,
                          margin: [5, 0, 0, 0],
                          // align verticaly center
                        },
                      ],
                    },
                  ],
                  style: "subheader",
                  pageBreak: "auto",
                  // Définir une hauteur minimale pour la cellule
                  height: 20, // Vous pouvez ajuster cette valeur en fonction de vos besoins
                },
              ],
            ],
          },
        },
        "\n",
        // {
        //   text: `Votre satisfaction : ${this.closer.satisfaction}`,
        //   style: "test",
        // },
        "\n",
        {
          text: `Nous espérons avoir répondu à vos attentes et restons à votre disposition pour toute information complémentaire.
          `,
          style: "test",
        },
        "\n",
        {
          text: `Pour votre parfaite information vous pourrez exercer un recours auprès du médiateur : contact@mediateur-geia.fr.`,
          style: "test",
        },
        "\n",
        {
          alignment: "right",
          columns: [
            {},
            {},
            {
              text: `L'équipe ANAVEL\rFait à Combourg\rLe : ${moment().format("DD/MM/YYYY")}`,

              style: "test",
            },
          ],
          // pageBreak: index < this.insurerSelected.length - 1 ? "after" : "",
        },
      )
      let documentData = []
      pdfMake.createPdf(dd).getDataUrl(function (outDoc) {
        document.getElementById("documentVisualisator").src = outDoc
        documentData.push({
          content: outDoc,
          filename: dd.info.title,
        })
      })

      setTimeout(() => {
        this.documentsData = documentData
      }, 2000)
    },
    sendmail() {
      try {
        emailjs.send("service_5p49t7p", "template_gsultfq", {
          to_name: `${this.complaint.civility} ${this.complaint.lastname} ${this.complaint.firstname}`,
          to_email: this.complaint.email,
          filename: this.documentsData[0].filename,
          content: this.documentsData[0].content,
          date_receive: this.complaint.date_received,
        })
      } catch (error) {
        console.log(error)
      }
    },
    getCollaboratorById(idCollaborator) {
      const collaborator = this.collaborators.find(collaborator => collaborator.id === idCollaborator)
      return collaborator
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    // openFileExplorer(idTask, idDisplay) {
    //   const agencyName = localStorage.getItem("agency").toLowerCase()
    //   const input = document.createElement("input")

    //   input.type = "file"
    //   // only word docx pdf
    //   input.accept = ".pdf, image/*"
    //   input.multiple = true
    //   input.addEventListener("change", event => {
    //     this.document = {
    //       name: event.target.files[0].name,
    //       data: event.target.files[0],
    //     }

    //     this.newAnotherTask.documents.push({
    //       url: `${config.ged}/${agencyName}/complaints/${this.complaint.id}/${this.document.data.name}`,
    //       name: this.document.data.name.replace(" ", "_"),
    //       type: this.document.data.type,
    //       size: this.formatBytes(this.document.data.size),
    //       date: new Date().toLocaleDateString(),
    //     })

    //     this.filesUrl.push(URL.createObjectURL(this.document.data))

    //     this.upload(idTask, idDisplay)
    //   })
    //   input.click()
    // },
    openFileExplorerEdit() {
      const agencyName = localStorage.getItem("agency").toLowerCase()
      const input = document.createElement("input")

      input.type = "file"
      // only word docx pdf
      input.accept = ".pdf, image/*"
      input.multiple = true
      input.addEventListener("change", event => {
        this.document = {
          name: event.target.files[0].name,
          data: event.target.files[0],
        }

        this.editComplaint.documents.push({
          data: this.document.data,
          url: `${config.ged}/${agencyName}/complaints/${this.complaint.id}/${this.document.data.name}`,
          name: this.document.data.name,
          type: this.document.data.type,
          size: this.formatBytes(this.document.data.size),
          date: new Date().toLocaleDateString(),
        })

        this.filesUrlEdit.push(URL.createObjectURL(this.document.data))
      })
      input.click()
    },
    openFileExplorer() {
      const agencyName = localStorage.getItem("agency").toLowerCase()
      const input = document.createElement("input")

      input.type = "file"
      // Accept only word docx pdf and images
      input.accept = ".pdf, .docx, .doc, .jpg, .jpeg, .png, .gif, .bmp, .tiff, image/*"
      input.multiple = true

      input.addEventListener("change", event => {
        const files = event.target.files
        const newDocuments = []

        for (let i = 0; i < files.length; i++) {
          const documentData = {
            name: files[i].name,
            data: files[i],
          }

          this.action.documents.push({
            data: documentData.data,
            name: documentData.name,
          })

          this.action.documentsInformations.push({
            url: `${config.ged}/${agencyName}/complaints/${this.complaint.id}/${documentData.name}`,
            name: documentData.name,
            type: documentData.data.type,
            size: this.formatBytes(documentData.data.size),
            date: new Date().toLocaleDateString(),
          })

          this.filesUrl.push(URL.createObjectURL(documentData.data))
        }
      })

      input.click()
    },
    openFileExplorer2() {
      const agencyName = localStorage.getItem("agency").toLowerCase()
      const input = document.createElement("input")

      input.type = "file"
      // Accept only word docx pdf and images
      input.accept = ".pdf, .docx, .doc, .jpg, .jpeg, .png, .gif, .bmp, .tiff, image/*"
      input.multiple = true

      input.addEventListener("change", event => {
        const files = event.target.files
        const newDocuments = []

        for (let i = 0; i < files.length; i++) {
          const documentData = {
            name: files[i].name,
            data: files[i],
          }

          this.newAnotherTask.documents.push({
            data: documentData.data,
            name: documentData.name,
          })

          this.newAnotherTask.documentsInformations.push({
            url: `${config.ged}/${agencyName}/complaints/${this.complaint.id}/${documentData.name}`,
            name: documentData.name,
            type: documentData.data.type,
            size: this.formatBytes(documentData.data.size),
            date: new Date().toLocaleDateString(),
          })

          this.filesUrl.push(URL.createObjectURL(documentData.data))
        }
      })

      input.click()
    },
    async upload2(idTask) {
      const agencyName = localStorage.getItem("agency").toLowerCase()
      const completeName = localStorage.getItem("complete_name")

      let formData = new FormData()

      this.newAnotherTask.documents.forEach(async document => {
        formData = new FormData()

        this.complaint.logs.push({
          info: `Le document ${
            document.name
          } à été ajoutés par ${completeName} sur la tâche n°${this.complaint.tasks.indexOf(idTask)}`,
          datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
          type: "edit",
        })

        formData.append("document", document.data)
        formData.append("documentName", document.name)
        formData.append("agencyName", agencyName)
        formData.append("idComplaint", this.complaint.id)
        formData.append("logs", JSON.stringify(this.complaint.logs))
        formData.append("idTask", idTask)
        // formData.append("data", JSON.stringify(this.newAnotherTask.documentsInformations))
        formData.append("place", "complaint")

        try {
          const res = await fetch(`${config.apiUri}/upload`, {
            method: "POST",
            body: formData,
          })

          if (res.ok) {
            this.action.documents = [] // Clear the array after successful upload
            this.action.documentsInformations = []
            this.newAnotherTask.documents = []
            this.newAnotherTask.documentsInformations = []
            this.filesUrl = []
          } else {
            alert("Erreur lors de l'upload des documents")
          }
        } catch (err) {
          console.error(err)
          alert("Erreur lors de l'upload des documents")
        }
      })
    },
    async uploadEdit(idComplaint) {
      const agencyName = localStorage.getItem("agency").toLowerCase()
      const completeName = localStorage.getItem("complete_name")

      let formData = new FormData()

      this.editComplaint.documents.forEach(async document => {
        formData = new FormData()

        this.complaint.logs.push({
          info: `Le document ${document.name} à été ajoutés par ${completeName} sur la réclamation n°${this.complaint.id_display}`,
          datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
          type: "edit",
        })

        formData.append("document", document.data)
        formData.append("documentName", document.name)
        formData.append("agencyName", agencyName)
        formData.append("idComplaint", idComplaint)
        formData.append("logs", JSON.stringify(this.complaint.logs))
        formData.append("place", "putComplaint")

        try {
          const res = await fetch(`${config.apiUri}/upload`, {
            method: "POST",
            body: formData,
          })
        } catch (err) {
          console.error(err)
          alert("Erreur lors de l'upload des documents")
        }
      })
    },

    async upload(idTask) {
      const agencyName = localStorage.getItem("agency").toLowerCase()
      const completeName = localStorage.getItem("complete_name")

      let formData = new FormData()

      this.action.documents.forEach(async document => {
        formData = new FormData()

        this.complaint.logs.push({
          info: `Le document ${
            document.name
          } à été ajoutés par ${completeName} sur la tâche n°${this.complaint.tasks.indexOf(idTask)}`,
          datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
          type: "edit",
        })

        formData.append("document", document.data)
        formData.append("documentName", document.name)
        formData.append("agencyName", agencyName)
        formData.append("idComplaint", this.complaint.id)
        formData.append("logs", JSON.stringify(this.complaint.logs))
        formData.append("idTask", idTask)
        // formData.append("data", JSON.stringify(this.newAnotherTask.documentsInformations))
        formData.append("place", "complaint")

        try {
          const res = await fetch(`${config.apiUri}/upload`, {
            method: "POST",
            body: formData,
          })

          if (res.ok) {
            this.action.documents = [] // Clear the array after successful upload
            this.action.documentsInformations = []
            this.newAnotherTask.documents = []
            this.newAnotherTask.documentsInformations = []
            this.filesUrl = []

            this.dialogAddTask = false
          } else {
            alert("Erreur lors de l'upload des documents")
          }
        } catch (err) {
          console.error(err)
          alert("Erreur lors de l'upload des documents")
        }
      })
    },

    // async upload(idTask, idDisplay) {
    //   this.complaint.logs.push({
    //     info: `Le document ${this.document.name} à été ajouté par ${localStorage.getItem(
    //       "complete_name",
    //     )} sur la tâche n°${idDisplay}`,
    //     datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
    //     type: "edit",
    //   })
    //   const formData = new FormData()
    //   formData.append("document", this.document.data)
    //   formData.append("documentName", this.document.name)
    //   formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
    //   formData.append("idComplaint", this.complaint.id)
    //   formData.append("logs", JSON.stringify(this.complaint.logs))
    //   formData.append("idTask", idTask)
    //   formData.append("data", JSON.stringify(this.newAnotherTask.documents))

    //   // formData.append("idCurrentTask")
    //   formData.append("place", "complaint")

    //   try {
    //     const res = await fetch(`${config.apiUri}/upload`, {
    //       method: "POST",
    //       body: formData,
    //     }).then(res => {
    //       if (res.ok) {
    //         this.document = {
    //           name: "",
    //           data: null,
    //         }
    //       } else {
    //         alert("Erreur lors de l'upload du PDF")
    //       }
    //     })
    //   } catch (err) {
    //     console.error(err)
    //     alert("Erreur lors de l'upload du PDF")
    //   }
    // },
    async postTask(task, idLastTask) {
      let idAgency = this.$store.state.user.agency_id
      let idComplaint = this.complaint.id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/complaints/${idComplaint}/tasks`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            lastname: this.$store.state.user.lastname,
            firstname: this.$store.state.user.firstname,
            idComplaint: this.complaint.id,
            idCollaborator: task.id_collaborator,
            comments_receiver: task.comments_receiver,
            comments_action: task.comments_action,
            action: task.action,
            date: task.date,
            receiver: task.receiver,
          }),
        })

        if (response.ok) {
          this.$toast.success("Tâche ajoutée avec succès", {
            position: "bottom-right",
            timeout: 3000,
          })
          this.dialogAddTask = false
          this.fetchComplaintById(this.complaint.id)
        } else {
          this.$toast.error("Une erreur est survenue", {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } catch (e) {
        console.error(e)
        this.$toast.error("Une erreur est survenue", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchUsers() {
      let idAgency = this.$store.state.user.agency_id
      this.collaborators = []

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/collaborators`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(element => {
          this.collaborators.push({
            id: element.id,
            lastname: element.lastname,
            firstname: element.firstname,
            email: element.email,
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    addTask() {
      this.complaint.tasks.push(actions)
    },
    async putComplaint() {
      const username = localStorage.getItem("complete_name")

      this.editComplaint.logs.push({
        info: `La réclamation a été modifiée par ${username} le ${moment().format("DD/MM/YYYY à HH:mm")}`,
        datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
        type: "edit",
      })

      const formData = new FormData()

      formData.append("idAgency", this.$store.state.user.agency_id)
      formData.append("lastname", this.editComplaint.lastname)
      formData.append("firstname", this.editComplaint.firstname)
      formData.append("gender", this.editComplaint.gender)
      formData.append("email", this.editComplaint.email)
      formData.append("phone", this.editComplaint.phone)
      formData.append("street", this.editComplaint.street)
      formData.append("city", this.editComplaint.city)
      formData.append("zip", this.editComplaint.zip)
      formData.append("reason", this.editComplaint.reason)
      formData.append("comments", this.editComplaint.comments)
      formData.append("idInsurer", this.editComplaint.id_insurer)
      formData.append("idProduct", this.editComplaint.id_product)
      formData.append("contractNumber", this.editComplaint.contract_number)
      formData.append("date", this.editComplaint.date_picker)
      formData.append("idComplexity", this.editComplaint.id_complexity)
      formData.append("idComplaint", this.editComplaint.id)
      formData.append("idCustomer", this.editComplaint.id_customer)
      formData.append("socialname", this.editComplaint.social_name)
      formData.append("data", JSON.stringify(this.editComplaint.documents))
      formData.append("logs", JSON.stringify(this.editComplaint.logs))

      formData.append("place", "putComplaint")

      // using fetch to post data
      fetch(`${config.apiUri}/php/pages/agencies_complaint.php`, {
        method: "POST",
        body: formData,
      })
        .then(res => {
          if (res.ok) {
            this.uploadEdit(this.editComplaint.id)
          } else {
            alert("Erreur lors de l'upload du PDF")
          }
        })
        .finally(() => {
          // this.uploadEdit(this.editComplaint.id)
          this.fetchComplaintById(this.editComplaint.id)
          this.dialogEditComplaint = false
          this.$toast.success("Modifié avec succès", {
            position: "bottom-right",
            timeout: 3000,
          })
          this.filesUrlEdit = []
          this.editComplaint.documents = []
          this.stepperEditComplaint = 1
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue", {
            position: "bottom-right",
            timeout: 3000,
          })
        })
      // this.uploadEdit(this.editComplaint.id)
    },
    async fetchInsurers() {
      this.loading = true

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/insurers/approved`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.insurersProposed.push({
            id: element.id,
            name: element.name,
          })
        })
        this.insurersProposed.sort(el => {
          return el.name
        })
        this.loading = false
        this.fetch = true
      } catch (e) {
        console.error(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchProducts() {
      this.loading = true
      let idAgency = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.productsProposed.push({
            id: element.id,
            name: element.name,
            icon: element.icon,
          })
        })

        this.loading = false
        this.fetch = true
      } catch (e) {
        console.error(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async putComplaintState(item, newState) {
      this.mailLoading = true
      const idAgency = this.$store.state.user.agency_id
      let action = ""

      switch (newState) {
        case 1:
          action = "open"
          break
        case 2:
          action = "close"
          break
        case 3:
          action = "lock"
          break
        case 4:
          action = "archive"
          break
      }

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/complaints/${item.id}/state`, {
          mode: "cors",
          method: "PUT",
          headers: headers,
          body: JSON.stringify({
            lastname: this.$store.state.user.lastname,
            firstname: this.$store.state.user.firstname,
            action: action,
          }),
        })

        if (response.status === 200) {
          this.complaint.id_state = newState
          this.$toast.success("Modifié avec succès", {
            position: "bottom-right",
            timeout: 3000,
          })

          this.fetchComplaintById(item.id)
        } else {
          this.$toast.error("Erreur lors de la modification", {
            position: "bottom-right",
            timeout: 3000,
          })
        }

        this.mailLoading = false
      } catch (e) {
        console.error(e)
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    editItem(complaint) {
      this.editComplaint = { ...complaint }
      this.editComplaint.documents.forEach(document => {
        this.filesUrlEdit.push(document.url)
      })
    },

    async fetchComplaintById(idComplaint) {
      this.waitTimeout = true
      this.loading = true
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/complaints/${idComplaint}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        if (response.status === 200) {
          const data = await response.json()

          let complaint = data[0]
          let agencyName = this.$store.state.user.agency_name
          let agencyNameShort = agencyName.substring(0, 3).toUpperCase()
          let now = moment().format("YYYY-MM-DD")
          this.complaint = {
            id: complaint.id,
            id_display: `#${agencyNameShort}-${complaint.id}`,
            lastname: complaint.lastname,
            firstname: complaint.firstname,
            date: moment(complaint.date_received).format("DD/MM/YYYY"),
            date_picker: moment(complaint.date_received).format("YYYY-MM-DD"),
            company_name: complaint.company_name,
            reason: complaint.reason,
            id_state: parseInt(complaint.id_complaint_state),
            comments: complaint.comments,
            gender: complaint.gender,
            civility: complaint.gender === "M" ? "Monsieur" : "Madame",
            email: complaint.email,
            phone: complaint.phone,
            phone_formatted: complaint.phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5"),
            street: complaint.street,
            zip: complaint.zip,
            city: complaint.city,
            date_received: moment(complaint.date_received).format("DD/MM/YYYY"),
            id_complexity: complaint.id_complaint_complexity,
            complexity_designation: complaint.complexity_designation,
            product_name: complaint.product_name,
            product_icon: complaint.product_icon,
            logs: complaint.logs === null ? [] : JSON.parse(complaint.logs),
            contract_number: complaint.contract_number,
            id_insurer: complaint.id_insurer,
            id_product: complaint.id_product,
            id_customer: complaint.id_customer,
            social_name: complaint.social_name,
            documents: complaint.documents === null ? [] : JSON.parse(complaint.documents),
            tasks: complaint.tasks,
            lastname_creator: complaint.lastname_creator,
            firstname_creator: complaint.firstname_creator,
            email_creator: complaint.email_creator,
            lastname_validator: complaint.lastname_validator,
            firstname_validator: complaint.firstname_validator,
          }

          this.complaint.tasks.forEach(task => {
            let time
            let anteriatory = false
            moment(now).isAfter(task.date_end) ? (anteriatory = true) : (anteriatory = false)
            anteriatory
              ? (time = moment(now).diff(moment(task.date_end).format("YYYY-MM-DD"), "days"))
              : (time = moment(task.date_end).diff(moment(now).format("YYYY-MM-DD"), "days"))

            task.documents = task.documents === null ? [] : JSON.parse(task.documents)

            task.anteriatory = anteriatory
            task.time = time
          })

          this.complaint.logs.sort((a, b) => {
            return new Date(b.datetime) - new Date(a.datetime)
          })

          this.complaint.logs.forEach(log => {
            log.datetime_display = moment(log.datetime).calendar()
          })
        } else {
          alert("Erreur lors de la récupération des données")
          this.$router.push({ name: "complaints" })
        }
        this.waitTimeout = false
      } catch (e) {
        console.error(e)
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
  },
}
</script>

<style>
.v-textarea {
  height: 100% !important;
}

.v-textarea .v-input__control {
  height: 100% !important;
}

.v-textarea .v-input__slot {
  height: 100% !important;
}
.timeline-container {
  max-height: 585px;
  overflow-y: auto;
}

.timeline-container::-webkit-scrollbar {
  width: 10px;
  height: 10px !important;
  background-color: #f5f5f5; /* Couleur de fond de la barre de défilement */
}
.timeline-container::-webkit-scrollbar-track {
  background-color: #fcc03c; /* Couleur de fond de la barre de défilement */
}

.timeline-container::-webkit-scrollbar-thumb {
  background-color: #001f47; /* Couleur de la barre de défilement */
  border-radius: 20px;
}

.hover-card-interlocutor:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
}

.theme--light.company-table th {
  background-color: #001f47 !important;
  font-size: 14px !important;
  color: #fcc03c !important;
}

.title {
  font-size: 0.875rem !important;
  font-weight: 1500 !important;
}

.hovered-input input {
  cursor: pointer !important;
}

.hovered-input .v-input__slot:hover {
  background-color: #e0e0e0 !important;
  transition: all 0.3s ease-in-out;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  z-index: 1;
}

.highlighted:hover:before {
  visibility: visible;
}

.image__zoom-icon {
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.profilAgencyAvatar {
  margin-bottom: 450px !important;
}

@media screen and (min-width: 2000px) {
  .profilAgencyAvatar {
    position: absolute;
    top: 200px;
    z-index: 0;
  }
}

@media screen and (min-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}

@media screen and (max-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}

.no-padding {
  padding: 0 !important;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}

.test {
  /*  remove bg color */
  background-color: transparent !important;
}
</style>
